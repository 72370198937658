// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-11032(cc9c192200)-C16/12/2024-18:24:04-B16/12/2024-18:53:58' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-11032(cc9c192200)-C16/12/2024-18:24:04-B16/12/2024-18:53:58",
  branch: "master",
  latestTag: "6.1",
  revCount: "11032",
  shortHash: "cc9c192200",
  longHash: "cc9c19220059d4b8f3f33b9b5af1eadefe685e2d",
  dateCommit: "16/12/2024-18:24:04",
  dateBuild: "16/12/2024-18:53:58",
  buildType: "Ansible",
  } ;
